body {
  margin: 0%;
  padding: 0%;
}
.chat-window {
  width: 350px;
  height: 420px;
}

.chat-window p {
  margin: 0;
}
.chat-window .chat-header {
  height: 45px;
  border-top-left-radius: 7px;
  border-top-right-radius: 7px;
  background: #263238;
  position: relative;
  cursor: pointer;
}
.chat-window .chat-header p {
  display: block;
  padding: 0 1em 0 2em;
  color: #fff;
  font-weight: 700;
  line-height: 45px;
}

.chat-window .chat-body {
  height: calc(450px - (45px + 70px));
  border: 1px solid #263238;
  background: bisque;
  position: relative;
}
.chat-window .chat-body .message-container {
  width: 100%;
  height: 100%;
  overflow-y: scroll;
  overflow-x: hidden;
}

.chat-window .chat-body .message-container::-webkit-scrollbar {
  display: none;
}
.chat-window .chat-body .message {
  height: auto;
  padding: 10px;
  display: flex;
}

.chat-window .chat-body .message .message-content {
  width: auto;
  height: auto;
  min-height: 40px;
  max-width: 120px;
  background-color: #47B5FF;
  border-radius: 5px;
  color: white;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow-wrap: break-word;
  word-break: break-word;
  padding: 0 10px;
}

#you {
  justify-content: flex-end;
}
#you .message-meta {
  justify-content: flex-start;
  margin-left: 5px;
}

#other {
  justify-content: flex-start;
}

#other .message-content {
  background-color: white;
  color: black;
}

#other .message-meta {
  justify-content: flex-end;
  margin-right: 5px;
}

.message-meta #author {
  margin-left: 10px;
  font-weight: bold;
}

.chat-window .chat-body .message .message-meta {
  display: flex;
  font-size: 12px;
}

.chat-window .chat-footer {
  height: 40px;
  border: 1px solid #263238;
  border-top: none;
  display: flex;
}

.chat-window .chat-footer input {
  height: 100%;
  flex: 85%;
  border: 0;
  padding: 0 0.7em;
  font-size: 1em;
  border-right: 1px dotted #607d8b;
  outline: none;
}

.chat-window .chat-footer button {
  border: 0;
  display: grid;
  place-items: center;
  cursor: pointer;
  flex: 15%;
  height: 100%;
  background: transparent;
  outline: none;
  font-size: 25px;
  color: lightgray;
}


.chat-window .chat-footer button:hover {
  color: white;
}
.hide {
  opacity: 0 !important;
}
